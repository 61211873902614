/*!
 * jQuery Cookie Policy Plugin
 */
(function($) {
$.fn.cookiepolicy = function(options) {
    new jQuery.cookiepolicy($(this), options);
	return this;
};

$.cookiepolicy = function(options) {

    var text = {
        pl: 'Używamy cookies i podobnych technologii m.in. w celach: świadczenia usług, reklamy, statystyk. Korzystanie z witryny bez zmiany ustawień Twojej przeglądarki oznacza, że będą one umieszczane w Twoim urządzeniu końcowym. Pamiętaj, że zawsze możesz zmienić te ustawienia.',
        en: 'We use cookies and similar technologies in order to deliver services. Using the website withouth changing your browser settings will result in using cookies on end device. Remember, you can always change your settings.'
    }

    var close = {
        pl: 'Zamknij',
        en: 'Close'
    }
	options = $.extend({
		cookie: 'cookiepolicyinfo',
		info: text[options.lang],
		close: close[options.lang]
	}, options || {});
	
    if($.cookie(options.cookie) != 'true') {
        var wrapper = $('<div/>').addClass('cookiepolicy').appendTo('body');
        $('<span/>').html(options.info).appendTo(wrapper);
        $('<a/>').addClass('button').html(options.close).appendTo(wrapper)
            .on('click', function(e) {
                e.preventDefault();
                $.cookie(options.cookie, true);
                $(this).parents('.cookiepolicy').remove();
            });
    }
};
})(jQuery);
